import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Divider, Drawer, Hidden, IconButton, Link as LinkTo, Stack, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router";
import NavBarItemMobile from "./NavBarItemMobile";
import { Close, Facebook, Instagram, Menu, YouTube } from "@mui/icons-material";
import NavBarItem from "./NavBarItem";
import { Link as RouterLink } from "react-router-dom";
// import { ReactComponent as Logo } from "../../assets/img/logo-min.svg";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import theme from "../../theme/index";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		zIndex: 100,
		transition: "0.2s",
		position: "fixed",
	},
	nav: {
		transition: "0.2s",
		borderBottom: "1px solid white",
	},
	navBar: {
		backgroundColor: theme.palette.background.main,
		boxShadow: "none",
	},
	navBarDown: {
		backgroundSize: "contain",
		backgroundAttachment: "fixed",
		paddingTop: 0,
		boxShadow: "none",
		maskSize: "cover",
		maskRepeat: "no-repeat",
		maskPosition: "center",
	},
	logo: {
		fill: "white",
	},
	maskTop: {
		width: "100%",
	},
	button: {
		paddingLeft: 20,
		paddingRight: 20,
		transition: "0.2s",
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	innerButton: {
		backgroundColor: "white",
		padding: "5px 10px 5px 10px",
		transition: "0.2s",

		"&:hover": {
			backgroundColor: theme.palette.primary.hover,
			"& .MuiTypography-root": {
				color: "white",
			},
		},
	},
	title: {
		color: theme.palette.background.main,
		transition: "0.2s",
		fontSize: "0.8rem !important",
		fontFamily: "Rubik",
		textTransform: "uppercase",
	},
	buttonMobile: {
		padding: "10px 20px 10px 20px",
		textDecoration: "none",
		borderTop: "3px solid #00000000",
		marginTop: "-3px !important",
		transition: "0.2s",
		color: theme.palette.background.color,
		width: 200,
		"&.active": {
			backgroundColor: theme.palette.background.color,
			borderBottom: "3px solid " + theme.palette.background.color,
			"& span": {
				color: theme.palette.text.primary,
			},
		},
		"&:hover": {
			backgroundColor: theme.palette.background.color,
			borderTop: "3px solid " + theme.palette.background.color,
			borderBottom: "3px solid " + theme.palette.background.color,
			"& span": {
				color: theme.palette.text.primary,
			},
		},
	},
}));

const TopBarPublic = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const [scrollY, setScrollY] = useState(window.scrollY);
	const navigate = useNavigate();
	const [menu, setMenu] = useState(null);
	const location = useLocation();

	const openMenu = (event) => {
		setMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setMenu(null);
	};

	const items = [
		{
			title: "Inici",
			to: "/",
		},
		{
			title: "Seccions",
			to: "seccions",
			dropdown: "true",
		},
		{
			title: "Activitats",
			to: "activitats",
		},
		{
			title: "Qui som",
			to: "qui-som",
		},
		{
			title: "Galeria",
			to: "galeries",
		},
		{
			title: "Contacte",
			to: "contacte",
		},
	];

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	return (
		<Box className={classes.main}>
			<AppBar elevation={0} className={classes.navBar}>
				<Toolbar className={clsx(classes.nav)}>
					<Hidden mdDown>
						<Box
							style={{
								flex: 1,
								display: "flex",
								justifyContent: "flex-start",
								cursor: "pointer",
							}}
							onClick={() => navigate("/")}
						>
							<Typography variant="h3" color="white">
								CEB
							</Typography>
						</Box>
					</Hidden>
					<Hidden mdDown>
						<Box
							style={{
								flex: 6,
								display: "flex",
								justifyContent: "center",
								height: 64,
							}}
						>
							{items?.map((item) => (
								<NavBarItem to={item.to} key={item.title} title={item.title} dropdown={item.dropdown} />
							))}
							<LinkTo
								className={classes.button}
								to={"https://cebergueda.playoffinformatica.com/preinscripcion/"}
								underline="none"
								component={RouterLink}
								target="_blank"
							>
								<Box className={classes.innerButton}>
									<Typography className={classes.title}>FES-TE SOCI</Typography>
								</Box>
							</LinkTo>
							<LinkTo
								className={classes.button}
								to={"https://cebergueda.playoffinformatica.com/PanellActivitatsWeb.php"}
								underline="none"
								component={RouterLink}
								target="_blank"
							>
								<Box className={classes.innerButton}>
									<Typography className={classes.title}>FEDERA'T</Typography>
								</Box>
							</LinkTo>
						</Box>
					</Hidden>

					<Hidden mdUp>
						<Box onClick={() => navigate("/")}>
							<Typography variant="h3" color="white">
								CEB
							</Typography>
						</Box>
						<IconButton
							style={{ zIndex: 10 }}
							color="primary"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={openMenu}
							className={classes.buttonDrawer}
						>
							<Menu color="secondary" />
						</IconButton>
						<Drawer open={menu} onClose={closeMenu} sx={{ ".MuiPaper-root": { backgroundColor: theme.palette.primary.hover } }}>
							<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
								<Box p={2.1}>
									<Typography color="white" variant="h3">
										CEB
									</Typography>
								</Box>
								<IconButton onClick={closeMenu}>
									<Close style={{ color: "white" }} />
								</IconButton>
							</Box>
							<Divider sx={{ borderColor: "white" }} />
							{items?.map(
								(item) =>
									item.to !== "seccions" && (
										<NavBarItemMobile to={item.to} key={item.title} title={item.title} closeMenu={closeMenu} dropdown={item.dropdown} />
									)
							)}
							<LinkTo
								className={classes.buttonMobile}
								to={"https://cebergueda.playoffinformatica.com/preinscripcion/"}
								underline="none"
								component={RouterLink}
								target="_blank"
							>
								<Box className={classes.innerButton}>
									<Typography>FES-TE SOCI</Typography>
								</Box>
							</LinkTo>
							<LinkTo
								className={classes.buttonMobile}
								to={"https://cebergueda.playoffinformatica.com/PanellActivitatsWeb.php"}
								underline="none"
								component={RouterLink}
								target="_blank"
							>
								<Box className={classes.innerButton}>
									<Typography>FEDERA'T</Typography>
								</Box>
							</LinkTo>
							<Box mt={5} display={"flex"} justifyContent={"center"}>
								<Logo
									style={{
										width: 100,
									}}
								/>
							</Box>
							<Box mt={2} display="flex" flexDirection={"column"}>
								<Box
									onClick={() => navigate("/avis-legal")}
									style={{
										textDecoration: "none",
										textAlign: "center",
										cursor: "pointer",
									}}
								>
									<Typography variant="footer">Avís Legal</Typography>
								</Box>

								<Box
									onClick={() => navigate("/politica-privacitat")}
									style={{
										textDecoration: "none",
										textAlign: "center",
										cursor: "pointer",
									}}
								>
									<Typography variant="footer">Política de Privacitat</Typography>
								</Box>
								<Stack direction={"row"} spacing={2} justifyContent={"center"} mt={1}>
									<a href="https://www.facebook.com/" target={"_blank"} rel="noreferrer">
										<Facebook color="info" />
									</a>
									<a href="https://www.instagram.com/ceb_berga/" target={"_blank"} rel="noreferrer">
										<Instagram color="info" />
									</a>

									<a href="https://www.youtube.com/@clubesquibergueda" target={"_blank"} rel="noreferrer">
										<YouTube color="info" />
									</a>
								</Stack>
							</Box>
						</Drawer>
					</Hidden>
					<Box
						style={{
							flex: 1,
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					></Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

TopBarPublic.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;
